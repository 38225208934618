<template>
  <fw-layout :footer="showFooter" extended-logo mobile-ready :back-to-enable="false">
    <template #header-nav>
      <div class="text-xs opacity-50">{{ appVersion }}</div>
    </template>

    <template #header-toolbar>
      <HeaderLanguagePanel />
    </template>

    <template #main-content>
      <div class="h-full flex flex-col">
        <div class="flex-1 flex justify-center items-center">
          <fw-panel boxed="xl">
            <div class="flex flex-row gap-2">
              <div class="w-[22rem] pb-2 relative flex justify-center items-center">
                <BlockPasswordlessLogin :links="false" :show-branding="true" size="xl" :show-instructions="true" />
              </div>
              <div class="w-12 border-l"></div>
              <div class="w-[32rem] pr-8 py-3">
                <form method="post" autocomplete="off" class="flex flex-col gap-5 h-full" @submit.prevent="doLogin">
                  <fw-heading size="h2" class="mb-5 sm:mb-8">{{ $t('title.ucAccount') }}</fw-heading>

                  <div class="flex-1">
                    <b-field
                      :label="isInstitutionalEmail ? 'Email institucional' : 'Email'"
                      label-for="email"
                      :type="{ 'is-danger': $v.email.$error }"
                      :message="$v.email.$error ? $t('message.form.emailRequired') : ''"
                    >
                      <b-input
                        v-model="email"
                        size="is-medium"
                        type="email"
                        @input="checkEmail"
                        @focus="keyboardInput = 'email'"
                      ></b-input>
                    </b-field>
                    <b-field
                      label="Password"
                      label-for="password"
                      :type="{ 'is-danger': $v.password.$error }"
                      :message="$v.password.$error ? $t('message.form.passwordRequired') : ''"
                    >
                      <b-input
                        v-model="password"
                        size="is-medium"
                        type="password"
                        name="password"
                        @focus="keyboardInput = 'password'"
                      />
                    </b-field>
                  </div>

                  <!-- Login errors -->
                  <div v-if="loginError" class="text-base">
                    <div class="font-bold text-sm mb-1 text-red-800">Ocorreu um erro:</div>
                    <div v-if="loginError" class="p-3 bg-red-50 rounded-lg mb-5 text-red-800 font-medium">
                      <div class="max-w-sm mx-auto text-center">
                        <div v-if="loginError == 'MaxAttempts'">
                          <div>Foi ultrapassado o limite de tentativas. Por favor, tente mais tarde.</div>
                        </div>
                        <div v-else-if="loginError == 'InvalidEmail'">
                          <div>O email indicado não parece ser válido.</div>
                        </div>
                        <div v-else-if="loginError == 'InvalidUCEmailOrPassword'">
                          <div>O email ou password não estão corretos ou a conta não existe.</div>
                        </div>
                        <div v-else-if="loginError == 'EmailNotActivated'">
                          <div>
                            <div>O email indicado corresponde a uma conta que ainda não foi ativada.</div>
                            <div class="text-sm mt-3">
                              Procure o email de validação que enviámos no momento do registo, na sua Inbox ou mesmo na
                              pasta SPAM.
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div>Não foi possível autenticar.</div>
                          <div class="text-sm font-bold p-2">Error: {{ loginError }}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <b-field class="mt-5">
                    <fw-button
                      expanded
                      :disabled="!canLogin"
                      :type="canLogin && !loginError ? 'primary' : 'black'"
                      size="xl"
                      @click.native="doLogin"
                      >{{ $t('button.login') }}</fw-button
                    >
                  </b-field>
                </form>
              </div>
            </div>
          </fw-panel>
        </div>
        <div v-if="false" class="items-center my-2 mx-auto">
          <fw-button
            type="link-muted"
            size="xs"
            class="opacity-20 hover:opacity-100"
            @click.native="showPinModal = true"
            >Entrar com PIN</fw-button
          >
        </div>
        <div v-if="showKeyboard" class="keyboard-open"></div>
      </div>
      <div class="text-center fixed bottom-0 select-none left-0 right-0 text-xs p-2 text-gray-400">
        <span>...{{ deviceKey }} - {{ deviceRef }} - {{ deviceDesc }}</span>
        <span :class="{ 'py-0.5 px-1 bg-yellow-600 text-white rounded': numberBackups > 0 }" @click="backupClick()"
          >B{{ numberBackups }}</span
        >
      </div>
      <div v-if="showKeyboard" class="absolute w-full bottom-0 left-0">
        <div class="max-w-screen-xl mx-auto">
          <BlockVirtualKeyboard
            key="keyboard"
            :input="keyboardInput == 'email' ? email : password"
            @onChange="onChangeKeyboard"
            @onKeyPress="onKeyPressKeyboard"
          />
        </div>
      </div>
    </template>

    <template #modals>
      <ModalCheckoutTablet v-if="false" :show="shouldCheckOut" :instance="lastExam" :show-keyboard="showKeyboard" />
      <ModalLoginPin v-if="showPinModal" :show="showPinModal" @close="showPinModal = false" />
      <b-modal v-model="showBackups" full-screen :can-cancel="false">
        <div class="flex h-full w-full bg-white p-5 gap-5">
          <div class="w-[18rem] overflow-y-scroll flex-shrink-0 flex flex-col gap-5">
            <div class="flex gap-5 justify-between">
              <fw-button v-if="backupsList.length" type="light-primary" @click.native="sendAllBackups"
                >Send all backups</fw-button
              >
              <fw-button type="xlight" @click.native="showBackups = false">Close</fw-button>
            </div>
            <div v-if="backupsList.length" class="flex flex-col gap-2">
              <fw-label size="xs">Backups</fw-label>
              <div v-for="bk in backupsList" :key="bk" class="cursor border-b pb-2 text-sm" @click="openBackup(bk)">
                {{ bk }}
              </div>
            </div>
            <div v-else class="text-sm text-gray-500">
              No backups in this device.
            </div>
          </div>
          <div v-if="currentBackup" class="flex-1 overflow-y-scroll font-mono pl-5 border-l">
            {{ currentBackup }}
          </div>
        </div>
      </b-modal>
    </template>
  </fw-layout>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators'
import debounce from 'lodash/debounce'
import BlockPasswordlessLogin from '@/fw-modules/fw-core-vue/id/components/blocks/BlockPasswordlessLogin'
import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'
import HeaderLanguagePanel from '@/fw-modules/fw-core-vue/ui/legacy/components/header/HeaderLanguagePanel'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import utilsId from '@/fw-modules/fw-core-vue/id/utils'
import BlockVirtualKeyboard from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockVirtualKeyboard'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import ModalCheckoutTablet from '@/fw-modules/fw-core-vue/id/components/modals/ModalCheckoutTablet'
import ModalLoginPin from '@/fw-modules/fw-core-vue/id/components/modals/ModalLoginPin'
import ServiceExams from '../../exams/services/ServiceExams'

export default {
  name: 'Login',

  components: {
    BlockPasswordlessLogin,
    HeaderLanguagePanel,
    BlockVirtualKeyboard,
    ModalCheckoutTablet,
    ModalLoginPin
  },

  data() {
    return {
      shouldCheckOut: localStorage.getItem('shouldCheckout') == 'true',
      lastExam: localStorage.getItem('lastExam') ?? null,
      page: 'qrcode',
      email: '',
      password: '',
      keepSignIn: false,
      emailResent: null,
      loginError: '',
      errorFound: '',
      isBrowserSafe: null,
      isEmailNotAllowed: false,
      supportEmail: 'support@ucframework.pt',
      showAnonymousJoin: process.env.VUE_APP_KEY == 'ucmeetings',
      institutionalDomains: utilsId.institutionalDomainsString(),
      debugMode: Boolean(localStorage.getItem('fw-debug')),
      redirectToRoom: false,
      countDown: 240,
      keyboardInput: '',
      deviceKey: localStorage.getItem('deviceKey') ? localStorage.getItem('deviceKey').slice(-3) : null,
      deviceRef: localStorage.getItem('deviceRef'),
      deviceDesc: localStorage.getItem('deviceDesc'),
      showPinModal: false,
      numberBackups: 0,
      clicks: 0,
      appVersion: process.env.VUE_APP_VERSION,
      appName: process.env.VUE_APP_KEY,
      showBackups: false,
      backupsList: [],
      currentBackup: null
    }
  },

  validations: {
    email: { required, email, min: minLength(5) },
    password: { required, min: minLength(1) },
    keepSignIn: false
  },

  computed: {
    showKeyboard() {
      return this.$route.meta.showKeyboard ?? false
    },
    allowExternalAccountsRegister() {
      return FwEnvConfig.allowExternalAccountsRegister
    },
    allowExternalAccountsLogin() {
      return FwEnvConfig.allowExternalAccountsLogin
    },
    isAllowedEmail() {
      return this.allowExternalAccountsRegister || this.allowExternalAccountsLogin || this.isInstitutionalEmail
    },
    canLogin() {
      return true
    },
    isInstitutionalEmail() {
      if (this.email) return utilsId.isInstitutionalEmail(this.email)
      return false
    },
    isExternalInstitutionalEmail() {
      if (this.email) return utilsId.isExternalInstitutionalEmail(this.email)
      return false
    },
    isMobile() {
      return utils.isMobile()
    },
    showFooter() {
      return !['ucdigitaldesk', 'ucdigitalpad'].includes(this.$store.state.appName)
    }
  },

  created() {
    this.email = this.email
    this.checkEmail = debounce(() => {
      this.isEmailNotAllowed = !this.isAllowedEmail
    }, 1500)

    // Parse redirect (if found)
    const query = this.$route.query
    if (query && query.redirect && query.redirect.startsWith('/live/')) {
      const redirectParts = query.redirect.split('/')
      this.redirectToRoom = redirectParts.length ? redirectParts.slice(-1)[0] : false
    }

    //calculate the number of backups in localstorage (start with backup_)
    let count = 0
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).startsWith('backup_')) count++
    }
    this.numberBackups = count
  },

  methods: {
    generateList() {
      let list = []
      for (let i = 0; i < localStorage.length; i++) {
        if (localStorage.key(i).startsWith('backup_')) list.push(localStorage.key(i))
      }
      this.backupsList = list
    },
    openBackup(key) {
      //get the backup and show it
      this.currentBackup = localStorage.getItem(key)
    },
    sendExamBackup(instanceKey, userKey) {
      return new Promise((resolve, reject) => {
        const key = `backup_${userKey}_${instanceKey}`
        let data = localStorage.getItem(key)
        try {
          if (data) {
            data = JSON.parse(data)
            ServiceExams.sendExamBackup(instanceKey, userKey, data)
              .then(() => {
                localStorage.removeItem(key)
                this.numberBackups--
                resolve()
              })
              .catch(error => {
                console.error('Error sending backup', error)
                reject(error)
              })
          } else {
            resolve()
          }
        } catch (error) {
          console.error('Error sending backup', error)
          reject(error)
        }
      })
    },
    backupClick() {
      if (this.numberBackups == 0) return
      this.clicks++
      if (this.clicks >= 5) {
        this.generateList()
        this.showBackups = true
      }
    },
    sendAllBackups() {
      this.$buefy.dialog.confirm({
        title: 'Send backups',
        message: 'Are you sure you want to send all backups?',
        confirmText: 'Yes',
        cancelText: 'No',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          let promises = []
          let sent = 0
          //TODO: SEND BACKUPS AND ERASE THEM ON SUCESSS
          for (let i = 0; i < localStorage.length; i++) {
            if (localStorage.key(i).startsWith('backup_')) {
              const key = localStorage.key(i)
              const parts = key.split('_')
              if (parts.length == 3) {
                promises.push(this.sendExamBackup(parts[2], parts[1]))
                sent++
              }
            }
          }
          if (sent > 0) {
            this.$buefy.snackbar.open({
              message: `Sending ${sent} backups...`,
              type: 'is-success',
              position: 'is-top-right',
              duration: 2000,
              queue: false
            })
          }
          Promise.all(promises)
            .then(() => {
              this.$buefy.snackbar.open({
                message: `All backups sent.`,
                type: 'is-success',
                position: 'is-top-right',
                duration: 2000,
                queue: false
              })
            })
            .catch(error => {
              console.error('Error sending backups', error)
              this.$buefy.snackbar.open({
                message: `Error sending backups.`,
                type: 'is-danger',
                position: 'is-top-right',
                duration: 2000,
                queue: false
              })
            })
        }
      })
    },
    onChangeKeyboard(input) {
      if (this.keyboardInput == 'email') this.email = input
      else this.password = input
    },
    onKeyPressKeyboard(button) {
      if (button === '{enter}') {
        this.doLogin()
      }
    },
    doLogin() {
      this.$v.$touch()

      this.emailResent = null
      this.errorFound = null

      if (!this.$v.$invalid) {
        this.$store
          .dispatch('login', {
            email: this.email,
            password: this.password,
            keepSignIn: this.keepSignIn
          })
          .then(response => {
            this.loginError = false
            let redirect = this.$route.query.redirect || '/'
            if (redirect.includes('logout')) redirect = '/'

            this.$router.push(redirect)
            return response
          })
          .catch(error => {
            this.loginError = utils.errors(error).getKey() || 'Undefined'
            console.warn(`login error: ${this.loginError}`, error)
          })
      }
    },

    async resendEmail() {
      this.errorFound = false
      let result = false

      try {
        result = await ServiceAuth.resendConfirmationEmail(this.email, process.env.VUE_APP_KEY)
      } catch (error) {
        this.errorFound = utils.errors(error).getKey() || 'Undefined'
        console.warn(`login error: ${this.errorFound}`, error)

        this.$buefy.snackbar.open({
          message: this.$t('error.tooManyAttempts'),
          type: 'is-danger',
          position: 'is-top-right',
          duration: 2000,
          queue: false
        })
        return
      }
      this.emailResent = result
    }
  }
}
</script>

<i18n>
{
  "en": {
    "uconelogin": "Login with UC One",
    "emaillogin": "Login with password",
    "title": {
      "authentication": "Authentication",
      "ucAccount": "Sign in with UC Account"
    },
    "button": {
      "login": "Login",
      "registerAccount": "Sign up",
      "sendValidationEmailAgain": "Send validation email again"
    },
    "label": {
      "keepActiveSession": "Keep me logged in"
    },
    "link": {
      "recoverPassword": "Recover password"
    },
    "message": {
      "toRecover": "Use <a href={link}>{link}</a> to recover the password of account <b>{{ email }}</b>.<br />",
      "validatingAccount": "Validating account...",
      "confirmedAccount": "Confirmed account",
      "checkEmail": "Check mailbox",
      "confirmEmailSent": "An email has been sent to you to confirm your account.",
      "resendConfirmEmail": "Resend confirmation email",
      "createAccountNotNeeded": "You don't need to create an account for",
      "notAllowedEmail": "To enter, use your institutional {application} account at the University of Coimbra",
      "noEmailArrived": "Didn't receive the email",
      "form": {
        "passwordConfirmation": "Confirm your password",
        "passwordConfirmationPlaceholder": "Password confirmation",
        "passwordRequired": "Password is required",
        "passwordSameAs": "Password confirmation must be same as password",
        "emailRequired": "Email is required"
      }
    },
    "login": {
      "setPassword": "Enter password.",
      "invalidPassword": "Wrong email or password.",
      "open": "Signin",
      "openApplication": "Signin",
      "register": "Signup",
      "recover": "Reset account",
      "signout": "Signout",
      "keepSession": "Keep session alive",
      "security": "If you're on a public device, please use a incognito/private browser window and signout from your account when done.",
      "pwdForgot": "Forgot password?",
      "authFailed": "Not possible to signin. Please, try again later.",
      "maxAttempts": "Maximum number of attempts reached. Please, try again later.",
      "emailNotRegistered": "It looks like your e-mail address is not registered.",
      "toSignin": "To signin,",
      "goRegisterPage": "go to the registration page",
      "doRegister": "and create a new account (in case you don't have an UC account) or link your institutional address."
    },
    "signup": {
      "label": "Register account",
      "resetCode": "Reset code is invalid.",
      "maxAttemptsReached": "Maximum nunber of attempts reached.",
      "emailregistered": "The provided e-mail address is already registered. Please provide a different e-mail address to continue.",
      "difficulties": "In case of trouble signing in, please try to ",
      "recoverPassword": "reset the password",
      "contactSupport": "or create a support request.",
      "pwdMinChars": "Your password needs a minimum of 8 characters.",
      "pwdMinLetters": "Your password needs a minimum of 3 letters.",
      "registrationNotPossible": "Account not registered.",
      "registrationSuccessful": "Your account has been successfully registered!",
      "validateAccount": "Please check your e-mail account to validate your e-mail address.",
      "checkSpam": "If you're having trouble finding our message, please, check your SPAM folder.",
      "finishSignin": "Please, signin to finish your registration.",
      "institutionalAccounts": "Para contas institucionais, a operação de registo confirma apenas a sua intenção em aceder à plataforma.",
      "institutionalSignin": "Após submeter o seu registo, deverá entrar na aplicação e utilizar o email",
      "institutionalSigninPwd": "e password da sua conta institucional.",
      "institutionalNotAvailable": "O seu endereço pertence a um grupo de domínios institucional entre os quais esta plataforma não consegue garantir a autenticação.<br />Assim, para entrar, por favor, defina uma password e valide a sua conta através da mensagem de confirmação que iremos enviar após registo.",
      "pwdValid": "Provide a valid password",
      "pwdMatch": "Passwords don't match.",
      "pwdSafe": "Provide a safe password, with a minimum of 8 characters, 3 of them being letters.",
      "pwdConfirm": "Confirm password",
      "privacyPoliceAcceptance": "Declaração de aceitação da Política de Privacidade e de Proteção de Dados",
      "backToSignin": "back to signin page",
      "error": "There was an error processing your registration"
    }
  },
  "pt": {
    "uconelogin": "Entrar com a UC One",
    "emaillogin": "Entrar com palavra-passe",
    "title": {
      "authentication": "Autenticação",
      "ucAccount": "Entrar com conta UC"
    },
    "button": {
      "login": "Entrar",
      "registerAccount": "Registar conta",
      "sendValidationEmailAgain": "Reenviar email de validação"
    },
    "label": {
      "keepActiveSession": "Manter sessão ativa"
    },
    "link": {
      "recoverPassword": "Recuperar password"
    },
    "message": {
      "toRecover": "Use <a href={link}>{link}</a> para recuperar a palavra-passe da conta <b>{{ email }}</b>.<br />",
      "validatingAccount": "A validar conta...",
      "confirmedAccount": "Conta confirmada",
      "checkEmail": "Verifique o seu email",
      "confirmEmailSent": "Um e-mail foi enviado para verificar a sua conta.",
      "resendConfirmEmail": "Reenviar email de confirmação",
      "createAccountNotNeeded": "Não necessita de criar uma conta para",
      "notAllowedEmail": "Para entrar em {application}, use a sua conta institucional da Universidade de Coimbra",
      "noEmailArrived": "Não recebeu o email",
      "form": {
        "passwordConfirmation": "Confirme a palavra-passe",
        "passwordConfirmationPlaceholder": "Confirmação de palavra-passe",
        "passwordRequired": "A palavra-passe é obrigatória",
        "passwordSameAs": "A confirmação da palavra-passe tem que ser igual",
        "emailRequired": "O email é obrigatório"
      }
    },
    "login": {
      "setPassword": "Insira palavra-passe.",
      "invalidPassword": "Email ou palavra-passe errados.",
      "open": "Entrar",
      "openApplication": "Entrar",
      "register": "Registar",
      "recover": "Recuperar",
      "signout": "Sair",
      "keepSession": "Manter sessão ativa",
      "security": "Se tiver num dispositivo público, por favor utilize uma janela de navegação privada e saia da sua conta quando terminar.",
      "pwdForgot": "Esqueceu-se da palavra-passe?",
      "authFailed": "Não foi possível iniciar sessão. Por favor, tente mais tarde.",
      "maxAttempts": "Número máximo de tentativas atingido. Por favor, tente mais tarde.",
      "emailNotRegistered": "Parece que o seu email não está registado.",
      "toSignin": "Para se registar,",
      "goRegisterPage": "abra a página de registo",
      "doRegister": "e crie uma nova conta (caso não tenha uma conta UC) ou associe o seu endereço institucional."
    },
    "signup": {
      "label": "Registar conta",
      "resetCode": "Código de recuperação inválido.",
      "maxAttemptsReached": "Número máximo de tentativas atingido.",
      "emailregistered": "O email fornecido já está registado. Por favor, forneça um email diferente para continuar.",
      "difficulties": "Em caso de dificuldades a iniciar sessão, por favor tente ",
      "recoverPassword": "recuperar a palavra-passe",
      "contactSupport": "ou crie um pedido de suporte.",
      "pwdMinChars": "A sua palavra-passe precisa de um mínimo de 8 caracteres.",
      "pwdMinLetters": "A sua palavra-passe precisa de um mínimo de 3 letras.",
      "registrationNotPossible": "Conta não registada.",
      "registrationSuccessful": "A sua conta foi registada com sucesso!",
      "validateAccount": "Por favor, verifique o seu email para validar o seu endereço de email.",
      "checkSpam": "Se tiver dificuldades em encontrar a nossa mensagem, por favor, verifique a sua pasta de SPAM.",
      "finishSignin": "Por favor, inicie sessão para finalizar o seu registo.",
      "institutionalAccounts": "Para contas institucionais, a operação de registo confirma apenas a sua intenção em aceder à plataforma.",
      "institutionalSignin": "Após submeter o seu registo, deverá entrar na aplicação e utilizar o email",
      "institutionalSigninPwd": "e password da sua conta institucional.",
      "institutionalNotAvailable": "O seu endereço pertence a um grupo de domínios institucional entre os quais a plataforma ainda não consegue garantir a autenticação institucional.<br />Assim, para entrar na plataforma, por favor, defina uma password e valide a sua conta através da mensagem de confirmação que iremos enviar após registo.",
      "pwdValid": "Forneça uma palavra-passe válida",
      "pwdMatch": "As palavras-passe não coincidem.",
      "pwdSafe": "Forneça uma palavra-passe segura, com um mínimo de 8 caracteres, 3 deles sendo letras.",
      "pwdConfirm": "Confirme a palavra-passe",
      "privacyPoliceAcceptance": "Declaração de aceitação da Política de Privacidade e de Proteção de Dados",
      "backToSignin": "voltar à página de entrada",
      "error": "Ocorreu um erro a processar o seu registo"
    }
  }
}
</i18n>

<style scoped>
.keyboard-open {
  height: 250px;
}
</style>
