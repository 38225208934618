<template>
  <DashboardHero
    :title="examName"
    title-position="center"
    type="cover"
    size="sm"
    :tag="
      mode == 'remote'
        ? $t('metadata_' + (isForm ? 'form' : 'exam') + '.remote')
        : $t('metadata_' + (isForm ? 'form' : 'exam') + '.presential')
    "
    tag-icon="survey"
    :loading="firstLoading"
  >
    <template v-if="status === 'withdraw' && !isRunning" #toolbar>
      <fw-tag type="danger" size="sm">Desistiu do exame</fw-tag>
    </template>
    <template v-if="showDefaultContent" #default>
      <div class="mb-10">
        <TextStatus
          v-if="!firstLoading && instance && instance.status == 'running'"
          class="mx-auto text-lg"
          :active="true"
          >{{ $t('status_' + (isForm ? 'form' : 'exam') + '.running') }}
        </TextStatus>
        <div v-else-if="!isDashboard" class="mx-auto text-white font-semibold opacity-70">
          {{ $t('status_' + (isForm ? 'form' : 'exam') + '.waiting') }}
        </div>
        <div v-if="!isWebSocketReady || showActions" class="h-14 mt-5 flex items-center justify-center">
          <div v-if="!isWebSocketReady" class="opacity-90 text-sm animate-pulse">A estabelecer ligação...</div>
          <div v-else-if="showActions">
            <fw-button
              v-if="canStart && !firstLoading && !loading"
              size="lg"
              :type="status === 'closed' || status === 'withdraw' ? 'white' : 'primary'"
              :disabled="!canStart"
              :loading="loading"
              custom-class="w-64"
              @click.native="startExam"
            >
              {{
                canStart
                  ? status === 'closed' || status === 'withdraw'
                    ? $t('action_' + (isForm ? 'form' : 'exam') + '.reopen')
                    : withAnswer
                    ? $t('action_' + (isForm ? 'form' : 'exam') + '.open')
                    : $t('action_' + (isForm ? 'form' : 'exam') + '.start')
                  : $t('action_' + (isForm ? 'form' : 'exam') + '.wait')
              }}
            </fw-button>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div v-if="firstLoading" class="flex gap-5 items-center w-full justify-center">
        <div class="animate-pulse h-10 rounded-md bg-gray-200 bg-opacity-10 w-24"></div>
        <div class="animate-pulse h-10 rounded-md bg-gray-200 bg-opacity-10 w-24"></div>
        <div class="animate-pulse h-10 rounded-md bg-gray-200 bg-opacity-10 w-24"></div>
      </div>
      <div v-else class="flex gap-x-10 text-center font-normal justify-center">
        <div>
          <fw-label color="text-white opacity-50">{{
            $t('metadata_' + (isForm ? 'form' : 'exam') + '.examkey')
          }}</fw-label>
          <div class="is-family-code font-medium">{{ instanceKey }}</div>
        </div>
        <div v-if="isDashboard">
          <fw-label color="text-white opacity-50">{{
            $t('metadata_' + (isForm ? 'form' : 'exam') + '.versions')
          }}</fw-label>
          <div class="font-medium">{{ versionsNumb }}</div>
        </div>
        <div v-if="sectionNumb > 0">
          <fw-label color="text-white opacity-50">{{
            $t('metadata_' + (isForm ? 'form' : 'exam') + '.sections')
          }}</fw-label>
          <div class="font-medium">{{ sectionNumb }}</div>
        </div>
        <div v-if="questionNumb > 0">
          <fw-label color="text-white opacity-50">{{
            $t('metadata_' + (isForm ? 'form' : 'exam') + '.questions')
          }}</fw-label>
          <div class="font-medium">{{ questionNumb }}</div>
        </div>

        <div v-if="instance && instance.duration > 0">
          <fw-label color="text-white opacity-50">
            {{ $t('metadata_' + (isForm ? 'form' : 'exam') + '.duration') }}
          </fw-label>
          <div class="font-medium">{{ examDuration | prettySeconds }}</div>
        </div>
        <div v-if="instance != null && instance.startDate != null">
          <fw-label color="text-white opacity-50">{{
            $t('metadata_' + (isForm ? 'form' : 'exam') + '.predictedStart')
          }}</fw-label>
          <div class="font-medium">{{ predictedStartDate }}</div>
        </div>
        <div v-if="isDashboard">
          <fw-label color="text-white opacity-50">{{
            $t('metadata_' + (isForm ? 'form' : 'exam') + '.access')
          }}</fw-label>
          <div class="font-medium">{{ access }}</div>
        </div>
        <div v-if="isDashboard && false">
          <fw-label color="text-white opacity-50">
            {{ $t('metadata_' + (isForm ? 'form' : 'exam') + '.participants') }}
          </fw-label>
          <div class="font-medium">{{ participantsNumb }}</div>
        </div>
      </div>
    </template>
  </DashboardHero>
</template>
<script>
//import InnerLoader from '@/fw-modules/fw-core-vue/ui/components/animation/InnerLoader'
import TextStatus from '@/fw-modules/fw-core-vue/exams/components/text/TextStatus'
import DashboardHero from '@/fw-modules/fw-core-vue/ui/components/dashboard/DashboardHero'

export default {
  name: 'PanelExamCover',
  components: { TextStatus, DashboardHero },
  props: {
    isForm: {
      type: Boolean,
      default: false
    },
    overrideExamName: {
      type: String,
      default: ''
    },
    isDashboard: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'presential'
    },
    showParticipantMessage: {
      type: Boolean,
      default: true
    },
    showActions: {
      type: Boolean,
      default: false
    },
    instanceKey: {
      type: String,
      required: true
    },
    firstLoading: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object
    },
    users: {
      type: Object
    },
    language: {
      type: String,
      default: 'pt'
    },
    instance: {
      type: Object
    },
    examEnded: {
      type: Boolean,
      default: false
    },
    isRunning: {
      type: Boolean,
      default: false
    },
    canStart: {
      type: Boolean,
      default: false
    },
    withAnswer: {
      type: Boolean,
      default: false
    },
    status: {
      type: String
    },
    background: {
      type: String,
      default: 'abstract-01'
    }
  },
  computed: {
    // Data
    isWebSocketReady() {
      return !!(this.$store.state.socket && this.$store.state.socket.connectionId)
    },
    participantsNumb() {
      return this.instance.allowed_users ? this.instance.allowed_users.length : 0
    },
    access() {
      return this.instance.options.isPublic
        ? this.$t('metadata_' + (this.isForm ? 'form' : 'exam') + '.open')
        : this.$t('metadata_' + (this.isForm ? 'form' : 'exam') + '.restricted')
    },
    versionsNumb() {
      return this.instance.forms.length
    },
    backgroundImage() {
      let bkimgMap = {
        'abstract-01': 'https://static.fw.uc.pt/banners/abstract-01.jpg',
        'abstract-02': 'https://static.fw.uc.pt/banners/abstract-02.jpg',
        'abstract-03': 'https://static.fw.uc.pt/banners/abstract-03.jpg'
      }
      return bkimgMap[this.background]
    },
    questionNumb() {
      return 0
    },
    sectionNumb() {
      return 0
    },
    predictedStartTime() {
      return '00:00'
    },
    predictedStartDate() {
      return this.instance != null && this.instance.startDate != null
        ? this.$options.filters.formatDayWithTime(this.instance.startDate)
        : ''
    },
    examDuration() {
      return this.instance && this.instance.duration ? this.instance.duration * 60 : 0
    },
    me() {
      return this.$store.getters.getUser
    },
    examCreator() {
      return this.users != null && this.users[this.instance.user_key] ? this.users[this.instance.user_key] : null
    },
    examDynamizer() {
      return this.users != null && this.instance.managers.length > 0 ? this.users[this.instance.managers[0]] : null
    },
    examName() {
      return this.instance && this.instance.title[this.language]
        ? this.instance.title[this.language]
        : this.instance.title['pt']
    },
    examInstructions() {
      return this.form && this.form.instructions[this.language]
    },
    availableLanguages() {
      return this.form && this.form.options.availableLanguages ? this.form.options.availableLanguages : []
    },
    randomMultiplechoiceOptions() {
      return this.form && this.form.options.randomMultiplechoiceOptions
        ? this.form.options.randomMultiplechoiceOptions
        : false
    },
    randomSections() {
      return this.form && this.form.options.randomSections ? this.form.options.randomSections : false
    },
    negativePoints() {
      return this.form && this.form.options.negativePoints ? this.form.options.negativePoints : false
    },
    limitNegativePoints() {
      return this.form && this.form.options.limitNegativePoints ? this.form.options.limitNegativePoints : false
    },
    examPoints() {
      return 0
    },
    autoMonitor() {
      return this.instance && this.instance.options.logInteractions ? this.instance.options.logInteractions : false
    },
    showDefaultContent() {
      return (
        !this.isDashboard ||
        !this.isWebSocketReady ||
        this.showActions ||
        (!this.firstLoading && this.instance && this.instance.status == 'running')
      )
    }
  },
  methods: {
    startExam() {
      if (this.status === 'withdraw') {
        this.$buefy.dialog.confirm({
          title: 'Reabrir exame após desistência',
          message: `Ao reabrir, o exame passará novamente a ser considerado para avaliação.`,
          cancelText: 'Cancelar',
          confirmText: 'Reabrir exame',
          type: 'is-primary',
          onConfirm: () => {
            this.$emit('start')
          }
        })
      } else {
        this.$emit('start')
      }
    }
  }
}
</script>

<i18n>
  {
    "en": {
      "status_exam": {
        "running": "In progress",
        "waiting": "Wait for the start of the exam",
        "ended": "Exam ended"
      },
      "status_form": {
        "running": "In progress",
        "waiting": "Wait for the start of the survey",
        "ended": "Survey ended"
      },
      "action_exam": {
        "open": "Open",
        "reopen": "Reopen",
        "continue": "Continue",
        "start": "Start exam",
        "wait": "Wait"
      },
      "action_form": {
        "open": "Open",
        "reopen": "Reopen",
        "continue": "Continue",
        "start": "Start survey",
        "wait": "Wait"
      },
      "metadata_exam": {
        "examkey": "Exame Key",
        "sections": "Sections",
        "questions": "Questions",
        "duration": "Duration",
        "predictedStartTime": "Predicted start time",
        "predictedStartDate": "Predicted start date",
        "predictedStart":  "Predicted start",
        "presential": "Presential exam",
        "remote": "Remote exam",
        "participants": "Participants",
        "access": "Access",
        "versions": "Versions",
        "open": "Public",
        "restricted": "Restricted"
      },
      "metadata_form": {
        "examkey": "Survey Key",
        "sections": "Sections",
        "questions": "Questions",
        "duration": "Duration",
        "predictedStartTime": "Predicted start time",
        "predictedStartDate": "Predicted start date",
        "predictedStart":  "Predicted start",
        "presential": "Presential survey",
        "remote": "Remote survey",
        "participants": "Participants",
        "access": "Access",
        "versions": "Versions",
        "open": "Public",
        "restricted": "Restricted"
      },
      "instructions_exam": {
        "attention": "Attention",
        "negativePointsLimit": "Selecting wrong options take points from the question",
        "negativePoints": "Selecting wrong options take points from the exam",
        "points": "Points",
        "autoMonitor": "This exam uses automated monitoring tech",
        "knowmore": "Saber mais",
        "examcreatedby": "Exam created by",
        "examdynamizedby": "Exam dynamized by",
        "yourid": "Your id"
      },
      "instructions_form": {
        "attention": "Attention",
        "negativePointsLimit": "Selecting wrong options take points from the question",
        "negativePoints": "Selecting wrong options take points from the exam",
        "points": "Points",
        "autoMonitor": "This survey uses automated monitoring tech",
        "knowmore": "Saber mais",
        "examcreatedby": "Survey created by",
        "examdynamizedby": "Survey dynamized by",
        "yourid": "Your id"
      }
    },
    "pt": {
      "status_exam": {
        "running": "Em curso",
        "waiting": "O exame não está disponível neste momento.",
        "ended": "Exame terminado."
      },
      "status_form": {
        "running": "Em curso",
        "waiting": "O questionário não está disponível neste momento.",
        "ended": "Questionário terminado."
      },
      "action_exam": {
        "open": "Abrir",
        "reopen": "Reabrir exame",
        "continue": "Continuar",
        "start": "Abrir exame",
        "wait": "Aguarde"
      },
      "action_form": {
        "open": "Abrir",
        "reopen": "Reabrir",
        "continue": "Continuar",
        "start": "Abrir Questionário",
        "wait": "Aguarde"
      },
      "metadata_exam": {
        "examkey": "Chave do exame",
        "sections": "Secções",
        "questions": "Questões",
        "duration": "Duração",
        "predictedStartTime": "Início previsto",
        "predictedStartDate": "Data prevista",
        "predictedStart":  "Início previsto",
        "presential": "Presencial",
        "remote": "Remoto",
        "participants": "Participantes",
        "access": "Acesso",
        "versions": "Versões",
        "open": "Aberto",
        "restricted": "Restrito"
      },
      "metadata_form": {
        "examkey": "Chave do questionário",
        "sections": "Secções",
        "questions": "Questões",
        "duration": "Duração",
        "predictedStartTime": "Início previsto",
        "predictedStartDate": "Data prevista",
        "predictedStart":  "Início previsto",
        "presential": "Questionário presencial",
        "remote": "Questionário remoto",
        "participants": "Participantes",
        "access": "Acesso",
        "versions": "Versões",
        "open": "Aberto",
        "restricted": "Restrito"
      },
      "instructions_exam": {
        "attention": "Atenção",
        "negativePointsLimit": "Selecionar opções erradas descontam na própria pergunta",
        "negativePoints": "Selecionar opções erradas descontam",
        "points": "Pontuação",
        "autoMonitor": "Este exame usa tecnologia de monitorização automática",
        "knowmore": "Saber mais",
        "examcreatedby": "Exame criado por",
        "examdynamizedby": "Exame dinamizado por",
        "yourid": "A sua identificação"
      },
      "instructions_form": {
        "attention": "Atenção",
        "negativePointsLimit": "Selecionar opções erradas descontam na própria pergunta",
        "negativePoints": "Selecionar opções erradas descontam",
        "points": "Pontuação",
        "autoMonitor": "Este questionário usa tecnologia de monitorização automática",
        "knowmore": "Saber mais",
        "examcreatedby": "Questionário criado por",
        "examdynamizedby": "Questionário dinamizado por",
        "yourid": "A sua identificação"
      }
    }
  }
  </i18n>
<style scoped>
.exam-cover-gradient {
  background: rgb(38, 38, 38);
  background: linear-gradient(0deg, rgba(38, 38, 38, 1) 25%, rgba(54, 54, 54, 0.48) 80%);
}
</style>
